import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import houseLogo from '../assets/logo-main.avif';
import '../styles.css';

const OurHousePage: React.FC = () => {
  const services = [
    {
      title: 'GIS (Geographic Information Systems)',
      items: [
        {
          name: 'Peta Tematik',
          description: 'Solusi visual yang spesifik sesuai kebutuhan Anda.'
        },
        {
          name: 'Pemetaan Udara (Drone Mapping)',
          description: 'Pemetaan presisi tinggi menggunakan teknologi drone.'
        },
        {
          name: 'Analisis Spasial',
          description: 'Konversi data geospasial menjadi wawasan strategis.'
        },
        {
          name: 'Peta Online Interaktif',
          description: 'Akses peta Anda kapan saja dan di mana saja melalui tautan atau barcode.'
        }
      ],
      tagline: 'Transformasi geospasial dimulai di sini!'
    },
    {
      title: 'Aplikasi dan Pengembangan Digital',
      items: [
        {
          name: 'Pengembangan Aplikasi Mobile',
          description: 'Aplikasi Android/iOS yang inovatif dan fungsional.'
        },
        {
          name: 'Chat Bot',
          description: 'Automasi interaksi pelanggan dengan solusi cerdas.'
        },
        {
          name: 'Landing Page',
          description: 'Desain profesional untuk kehadiran online yang memikat.'
        },
        {
          name: 'Web App',
          description: 'Solusi berbasis web yang cepat dan andal.'
        }
      ],
      tagline: 'Ayo wujudkan aplikasi impian Anda bersama kami!'
    },
    {
      title: 'Social Media Organizer',
      items: [
        {
          name: 'Strategi Konten',
          description: 'Rencana konten yang menarik dan relevan untuk audiens Anda.'
        },
        {
          name: 'Manajemen Kampanye',
          description: 'Optimasi kampanye media sosial untuk hasil maksimal.'
        },
        {
          name: 'Desain Grafis dan Video Pendukung',
          description: 'Visual profesional untuk mendukung merek Anda.'
        }
      ],
      tagline: 'Raih perhatian audiens Anda dengan konten yang benar-benar menonjol!'
    },
    {
      title: 'Video Editing dan Produksi Kreatif',
      items: [
        {
          name: 'Editing Profesional',
          description: 'Transformasi footage menjadi video berkualitas tinggi.'
        },
        {
          name: 'Motion Graphics dan Animasi',
          description: 'Tambahkan sentuhan kreatif pada video Anda.'
        },
        {
          name: 'Video Iklan dan Media Sosial',
          description: 'Tingkatkan brand awareness dengan produksi berkualitas.'
        }
      ],
      tagline: 'Buat cerita Anda hidup dengan visual yang tak terlupakan!'
    },
    {
      title: 'Data Visualization',
      items: [
        {
          name: 'Grafik Interaktif',
          description: 'Solusi visual yang informatif dan menarik.'
        },
        {
          name: 'Output Beragam',
          description: 'Tersedia dalam berbagai format seperti dashboard interaktif atau grafik statis.'
        },
        {
          name: 'Manfaat Nyata',
          description: 'Tingkatkan pengambilan keputusan berbasis data dengan visual yang intuitif.'
        }
      ],
      tagline: 'Biarkan data berbicara melalui visual yang menarik!'
    },
    {
      title: 'E-Learning Solutions',
      items: [
        {
          name: 'Kelas Online Interaktif',
          description: 'Modul pembelajaran berbasis video, kuis, dan animasi.'
        },
        {
          name: 'Platform Pembelajaran',
          description: 'Solusi lengkap untuk pelatihan internal atau pendidikan formal.'
        },
        {
          name: 'Microlearning',
          description: 'Modul pembelajaran singkat yang efektif dan mudah diakses.'
        }
      ],
      tagline: 'Bawa pembelajaran Anda ke era digital!'
    },
    {
      title: 'Custom Digital Tools',
      items: [
        {
          name: 'Workflow Automation',
          description: 'Otomatisasi proses kerja untuk efisiensi maksimal.'
        },
        {
          name: 'Dashboard Bisnis',
          description: 'Analisis dan laporan performa dalam satu tampilan.'
        },
        {
          name: 'Alat Digital Khusus',
          description: 'Dirancang sesuai spesifikasi unik Anda untuk meningkatkan produktivitas.'
        }
      ],
      tagline: 'Solusi digital unik untuk kebutuhan spesifik Anda!'
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header Section */}
      <header className="flex flex-col lg:flex-row items-center mb-12 animate-fadeIn">
        <img
          src={houseLogo}
          alt="Our House Logo"
          className="w-40 h-40 mb-6 lg:mb-0 lg:mr-6 rounded-full shadow-lg animate-bounce"
        />
        <div>
          <h1 className="text-7xl font-extrabold text-blue-900 mb-4">Our House</h1>
          <p className="text-2xl text-gray-700 italic">Kolaborasi dan Inovasi Digital</p>
        </div>
      </header>

      {/* Services Section */}
      <section className="space-y-16">
        {services.map((service, index) => (
          <div key={index} className="bg-gradient-to-br from-blue-50 to-blue-200 rounded-lg shadow-xl p-8 transform hover:scale-105 transition-transform duration-300">
            <h2 className="text-5xl font-bold text-blue-800 mb-8 text-center border-b-4 border-blue-400 pb-4">{service.title}</h2>
            <div className="grid md:grid-cols-3 gap-8">
              {service.items.map((item, idx) => (
                <div
                  key={idx}
                  className="bg-white border border-blue-200 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow duration-300 hover:translate-y-1"
                >
                  <h3 className="text-3xl font-semibold text-blue-700 mb-4">{item.name}</h3>
                  <p className="text-gray-700 text-lg">{item.description}</p>
                </div>
              ))}
            </div>
            <p className="text-center text-blue-700 italic font-medium mt-8 text-xl">{service.tagline}</p>
          </div>
        ))}
      </section>

      {/* WhatsApp Button */}
      <div className="mt-16 text-center animate-fadeIn">
        <a
          href="https://wa.me/6282151155507"
          className="bg-blue-600 text-white px-8 py-4 rounded-full inline-flex items-center shadow-lg hover:bg-blue-700 transition duration-300 hover:shadow-xl hover:scale-110"
        >
          <FaWhatsapp className="mr-3 text-2xl" />
          Hubungi Kami via WhatsApp
        </a>
      </div>
    </div>
  );
};

export default OurHousePage;
