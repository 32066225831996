import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaTwitter, FaInstagram, FaYoutube, FaEnvelope } from 'react-icons/fa';
import logo from '../assets/logo-main.avif';
import LogoLearning from '../assets/logo-learning.avif';
import LogoHouse from '../assets/logo-house.avif';
import '../styles.css';
import { Link } from 'react-router-dom'; // Assuming React Router is used
import { motion, AnimatePresence } from 'framer-motion';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"


const LandingPage: React.FC = () => {
  const [isEnglish, setIsEnglish] = useState(true);

  // Toggle language every 15 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setIsEnglish((prev) => !prev);
    }, 15000); // 15 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const text = isEnglish
    ? 'Building a future through collaboration, learning, and innovation.'
    : 'Membangun masa depan melalui kolaborasi, pembelajaran, dan inovasi.';

  return (
    <div className="bg-light-blue text-dark-blue font-nunito">
      {/* Hero Section */}
        <section id="hero" className="hero-fullscreen flex items-center justify-center text-center">
          <div className="max-w-4xl">
            {/* Logo */}
            <img src={logo} alt="Ourdo Logo" className="w-36 h-36 mx-auto mb-6" />

            {/* Title */}
            <h1 className="text-6xl font-extrabold mb-4 fade-in-up">Ourdo.id</h1>

            {/* Animated Motto on One Line */}
            <div className="flex items-center justify-center text-lg font-medium text-gray-700 space-x-2">
              <div className="relative inline-block w-auto">
                <AnimatePresence mode="wait">
                  <motion.span
                    key={text} // Ensures animation on text change
                    className="text-accent font-bold"
                    initial={{ opacity: 0, x: 50 }} // Slide in from the right
                    animate={{ opacity: 1, x: 0 }} // Animate to center
                    exit={{ opacity: 0, x: -50 }} // Slide out to the left
                    transition={{ duration: 0.8 }}
                  >
                    {text}
                  </motion.span>
                </AnimatePresence>
              </div>
            </div>

            {/* Static Motto */}
            <p className="text-lg italic text-dark-accent mt-4">Membangun Masa Depan dari Langkah Kecil</p>
          </div>
        </section>



      {/* Vision, Mission, and Core Values */}
      <section id="vision-mission" className="container mx-auto px-8 py-16 text-center bg-white shadow-lg rounded-lg">
        <h2 className="text-5xl font-semibold mb-8 text-dark-blue">Tentang Kami</h2>
        <div className="text-left space-y-6">
          <div>
            <h3 className="text-3xl font-bold text-dark-blue mb-4">Visi</h3>
            <p className="text-gray-700">
              Menjadi platform terkemuka yang memfasilitasi <span className="text-accent font-bold">kolaborasi</span>,{' '}
              <span className="text-accent font-bold">pembelajaran</span>, dan{' '}
              <span className="text-accent font-bold">inovasi</span> untuk masa depan yang lebih baik.
            </p>
          </div>
          <div>
            <h3 className="text-3xl font-bold text-dark-blue mb-4">Misi</h3>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>Menyediakan layanan inovatif yang mendukung manajemen organisasi melalui pendekatan kreatif.</li>
              <li>Meningkatkan keterampilan individu dan komunitas melalui program pembelajaran yang relevan.</li>
              <li>Membangun ruang kolaborasi yang mendorong kreativitas dan kerja sama tim.</li>
            </ul>
          </div>
          <div>
            <h3 className="text-3xl font-bold text-dark-blue mb-4">Core Values</h3>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li><strong className="text-accent">C</strong>: Creative (kreatif) - Menginspirasi kreativitas tanpa batas.</li>
              <li><strong className="text-accent">O</strong>: Outstanding (Luar Biasa) - Berupaya mencapai hasil yang luar biasa.</li>
              <li><strong className="text-accent">D</strong>: Dynamic (Dinamis) - Beradaptasi dengan solusi yang fleksibel.</li>
              <li><strong className="text-accent">E</strong>: Excellent (Unggul) - Berkomitmen pada keunggulan dalam setiap proses.</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Products Section */}
      <section id="products" className="container mx-auto px-8 py-16 text-center">
        <h2 className="text-5xl font-semibold mb-12 text-dark-blue">Produk Kami</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {[
            {
              title: 'Our Event',
              description: 'Mitra Anda dalam mengelola acara dengan kreativitas dan efisiensi.',
              link: '/product/our-event',
              imgSrc: logo,
            },
            {
              title: 'Our Learning',
              description: 'Memberikan pengalaman pembelajaran yang interaktif dan relevan.',
              link: '/product/our-learning',
              imgSrc: LogoLearning,
            },
            {
              title: 'Our House',
              description: 'Ruang kolaboratif untuk inovasi dan kerja sama tim.',
              link: '/product/our-house',
              imgSrc: LogoHouse,
            },
          ].map((product, index) => (
            <Link
              key={index}
              to={product.link}
              className="shadow-lg p-8 rounded-full bg-white hover:shadow-xl transition-transform transform hover:-translate-y-2 text-center"
            >
              <img src={product.imgSrc} alt={product.title} className="w-24 h-24 mx-auto mb-4 rounded-full" />
              <h3 className="text-2xl font-medium mb-4 text-dark-blue">{product.title}</h3>
            </Link>
          ))}
        </div>
      </section>

      {/* Footer */}
      <footer id="footer" className="bg-white py-12 text-center">
        <p className="text-lg font-semibold mb-4">Ikuti Kami</p>
        <div className="flex justify-center items-center space-x-6 mb-4">
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="w-8 h-8 text-blue-500 hover:text-blue-700 transition-transform transform hover:scale-110" />
          </a>
          <a href="https://wa.me/6282151155507" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp className="w-8 h-8 text-green-500 hover:text-green-700 transition-transform transform hover:scale-110" />
          </a>
          <a href="https://instagram.com/ourdo.id" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="w-8 h-8 text-pink-500 hover:text-pink-700 transition-transform transform hover:scale-110" />
          </a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="w-8 h-8 text-red-500 hover:text-red-700 transition-transform transform hover:scale-110" />
          </a>
          <a href="mailto:business@ourdo.id" target="_blank" rel="noopener noreferrer">
            <FaEnvelope className="w-8 h-8 text-gray-500 hover:text-gray-700 transition-transform transform hover:scale-110" />
          </a>
        </div>
        <p className="text-gray-600 text-sm">© {new Date().getFullYear()} Ourdo. Semua hak dilindungi.</p>
      </footer>
       {/* Add Vercel Analytics */}
       <Analytics />
       <SpeedInsights/>
    </div>
  );
};

export default LandingPage;
