import React, { useRef, useState, useEffect } from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Tambahkan icon WhatsApp
import eventLogo from '../assets/logo-main.avif'; // Ganti dengan path logo Anda
import '../styles.css';

const OurEventPage: React.FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [showWhyChoose, setShowWhyChoose] = useState(false);

  const checkScrollPosition = () => {
    const container = scrollRef.current;
    if (!container) return;
    const { scrollLeft, scrollWidth, clientWidth } = container;
    setIsAtStart(scrollLeft === 0);
    setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
  };

  const scrollLeft = () => {
    scrollRef.current?.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current?.scrollBy({ left: 300, behavior: 'smooth' });
  };

  useEffect(() => {
    const container = scrollRef.current;
    if (!container) return;
    container.addEventListener('scroll', checkScrollPosition);
    return () => container.removeEventListener('scroll', checkScrollPosition);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setShowWhyChoose(entry.isIntersecting),
      { threshold: 0.1 }
    );
    const target = document.querySelector('#why-choose');
    if (target) observer.observe(target);
    return () => {
      if (target) observer.unobserve(target);
    };
  }, []);

  const packages = [
    {
      name: 'Our Event Basic',
      features: [
        'Moderator acara',
        'Poster digital',
        'Background virtual standar',
        'Rundown sederhana',
      ],
      description: 'Solusi praktis untuk acara kecil dan komunitas.',
    },
    {
      name: 'Our Event Lite',
      features: [
        'Semua dari Basic',
        'Undangan digital dengan RSVP',
        'Rundown terperinci',
      ],
      description: 'Ideal untuk webinar komunitas atau pelatihan kecil.',
    },
    {
      name: 'Our Event Standard',
      features: [
        'Semua dari Lite',
        'Tiket digital QR code',
        'Statistik kehadiran peserta',
      ],
      description: 'Pilihan tepat untuk acara menengah dengan kebutuhan tambahan.',
    },
    {
      name: 'Our Event Pro',
      features: [
        'Semua dari Standard',
        'Sertifikat digital otomatis',
        'Streaming di YouTube',
      ],
      description: 'Dirancang untuk pelatihan atau seminar profesional.',
    },
    {
      name: 'Our Event Premium',
      features: [
        'Semua dari Pro',
        'Desain premium poster & undangan',
        'Highlight video pasca acara',
      ],
      description: 'Sempurna untuk konferensi skala besar atau acara formal.',
    },
    {
      name: 'Our Event Custom',
      features: [
        'Pilih layanan sesuai kebutuhan',
        'Sertifikat, tiket, undangan, poster, moderator, rundown, dan dokumentasi',
        'Fleksibel untuk acara dengan skala dan anggaran apa pun',
        'Diskon khusus untuk acara edukasi',
      ],
      description: 'Dapatkan layanan yang dirancang sepenuhnya sesuai kebutuhan Anda.',
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header Section */}
      <header className="flex flex-col lg:flex-row items-center mb-8">
        <img
          src={eventLogo}
          alt="Our Event Logo"
          className="w-36 h-36 mb-6 lg:mb-0 lg:mr-6 rounded-full shadow-lg animate-bounce"
        />
        <div>
          <h1 className="text-5xl font-bold text-dark-blue mb-2">Our Event</h1>
          <p className="text-lg text-gray-600">Mitra terpercaya Anda untuk acara online</p>
        </div>
      </header>

      {/* Packages Section */}
      <section className="mb-16 relative">
        <h2 className="text-4xl font-bold text-dark-blue mb-6 text-center">Paket Layanan</h2>

        {/* Left Arrow */}
        {!isAtStart && (
          <button
            onClick={scrollLeft}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-transparent text-black p-4 rounded-full shadow-none hover:scale-150 transition duration-300 opacity-75 hover:opacity-100 z-10"
          >
            <span style={{ fontSize: '3rem', fontWeight: 'bold' }}>‹</span>
          </button>
        )}

        {/* Packages */}
        <div
          ref={scrollRef}
          className="flex gap-8 overflow-x-auto scrollbar-hide scroll-smooth"
        >
          {packages.map((pkg, index) => (
            <div
              key={index}
              className="bg-gradient-to-br from-blue-100 via-blue-200 to-blue-300 text-dark-blue shadow-lg rounded-lg p-8 min-w-[350px] hover:scale-105 hover:shadow-2xl transition-all duration-300"
            >
              <h3 className="text-2xl font-semibold mb-4 text-center">{pkg.name}</h3>
              <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
                {pkg.features.map((feature, idx) => (
                  <li key={idx}>{feature}</li>
                ))}
              </ul>
              <p className="text-sm text-gray-500 italic text-center">{pkg.description}</p>
            </div>
          ))}
        </div>

        {/* Right Arrow */}
        {!isAtEnd && (
          <button
            onClick={scrollRight}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-transparent text-black p-4 rounded-full shadow-none hover:scale-150 transition duration-300 opacity-75 hover:opacity-100 z-10"
          >
            <span style={{ fontSize: '3rem', fontWeight: 'bold' }}>›</span>
          </button>
        )}
      </section>

      {/* Why Choose Our Event */}
      <section
        id="why-choose"
        className={`bg-gray-100 p-6 rounded-lg shadow-lg mt-16 transition-all duration-500 ${
          showWhyChoose ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}
      >
        <h2 className="text-4xl font-bold text-dark-blue mb-4 text-center">Mengapa Memilih Our Event?</h2>
        <ul className="list-disc list-inside text-gray-700 space-y-4">
          <li>Fleksibel untuk berbagai skala acara, mulai dari komunitas kecil hingga konferensi besar.</li>
          <li>Tim profesional yang memastikan kelancaran acara Anda dari awal hingga akhir.</li>
          <li>Spesialisasi dalam mendukung acara edukasi dengan layanan terbaik dan penawaran khusus.</li>
        </ul>
      </section>

      {/* WhatsApp Button */}
      <div className="mt-16 text-center">
        <a
          href="https://wa.me/6282151155507" // Ganti nomor WhatsApp dengan nomor Anda
          className="bg-green-500 text-white px-6 py-3 rounded-full inline-flex items-center shadow-lg hover:bg-green-600 transition duration-300"
        >
          <FaWhatsapp className="mr-2 text-xl" />
          Hubungi Kami via WhatsApp
        </a>
      </div>
    </div>
  );
};

export default OurEventPage;
