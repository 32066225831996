import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import learningLogo from '../assets/logo-learning.avif';
import '../styles.css';

const OurLearningPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header Section */}
      <header className="flex flex-col lg:flex-row items-center mb-12 animate-fadeIn">
        <img
          src={learningLogo}
          alt="Our Learning Logo"
          className="w-40 h-40 mb-6 lg:mb-0 lg:mr-6 rounded-full shadow-lg animate-bounce"
        />
        <div>
          <h1 className="text-7xl font-extrabold text-green-900 mb-4">Our Learning</h1>
          <p className="text-2xl text-gray-700 italic">Eksplorasi dan Pertumbuhan Tanpa Batas</p>
        </div>
      </header>

      {/* Coming Soon Section */}
      <section className="bg-gradient-to-br from-green-50 to-green-200 rounded-lg shadow-xl p-8 text-center transform hover:scale-105 transition-transform duration-300">
        <h2 className="text-5xl font-bold text-green-800 mb-8">Coming Soon</h2>
        <p className="text-gray-700 text-xl italic mb-6">
          Kami sedang mempersiapkan platform pembelajaran terbaik untuk mendukung pengembangan Anda. Tunggu kehadirannya segera!
        </p>
        <div className="mt-8">
          <a
            href="https://wa.me/6282151155507"
            className="bg-green-600 text-white px-8 py-4 rounded-full inline-flex items-center shadow-lg hover:bg-green-700 transition duration-300 hover:shadow-xl hover:scale-110"
          >
            <FaWhatsapp className="mr-3 text-2xl" />
            Hubungi Kami untuk Info Lebih Lanjut
          </a>
        </div>
      </section>
    </div>
  );
};

export default OurLearningPage;
