import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './views/LandingPage';
import OurEventPage from './views/OurEventPage';
import OurHousePage from './views/OurHousePage';
import OurLearningPage from './views/OurLearningPage';

const App: React.FC = () => {
  return (
    <Routes>
      {/* Main Landing Page */}
      <Route path="/" element={<LandingPage />} />
      
      {/* Grouped Routes under /product */}
      <Route path="/product">
        <Route path="our-event" element={<OurEventPage />} />
        <Route path="our-house" element={<OurHousePage />} />
        <Route path="our-learning" element={<OurLearningPage />} />
      </Route>
    </Routes>
  );
};

export default App;
